.shopping-item-icon-container {
  background: white;
}
.main-item-wrap {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  position: relative;
  .item-content-display {
    display: none;
    // transition: opacity 0.3s ease, visibility 0s linear 2s; /* Add transition */
    // opacity: 0;
    // visibility: hidden;
  }
  &:hover {
    .item-content-display {
      display: block;
      // opacity: 1;
      // visibility: visible;
      // transition-delay: 7s;
      width: 104px;
      max-height: 300px;
      position: absolute;
      color: aliceblue;
      white-space: wrap;
      // text-align: justify;
      // text-justify: inter-word;
      // max-width: 450px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
      // width: fit-content;
      // min-width: 20px;
      // padding: 12px;
      border-radius: 10px;
      background-color: rgba(14, 14, 14, 0.8);
      white-space: pre-wrap;
      font-weight: bold;
      font-size: 20px;
      font-family: "Nunito Sans";
      padding: 10px;
      color: rgb(255, 254, 254);

      // bottom: 40px;
      // top: -100px;

      img {
        width: 100%;
        height: 60px;
        // margin: 5px 5%;
        padding-bottom: 10px;
        border-radius: 15px;
      }
      h3 {
        font-size: 12px;
        margin-bottom: 2px;
      }
      p {
        font-size: 10px;
      }
      .item-content-display-hover {
        padding-top: 0;
      }
    }
    .extra-desc-styles {
      z-index: 1;
      width: 150px;
      img {
        width: 100%;
        height: 120px;
        border-radius: 0;
      }
      h3 {
        margin-bottom: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        margin-bottom: 3px;
      }
      p {
        margin-bottom: 3px;
        width: 100%;
        font-weight: 300;
        overflow: hidden;

        white-space: wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* Number of lines to show */
        text-overflow: ellipsis;
        white-space: wrap;
      }
    }
    .extra-card-styles {
      width: 150px;
      padding: 10px;
      z-index: 1;
      img {
        width: 100%;
        height: auto;
        max-height: 120px;
        border-radius: 0;
      }
      h3 {
        margin-bottom: 0;
        width: 100%;
        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        margin-bottom: 2px;
      }
      p {
        margin-bottom: 2px;
        width: 100%;
        overflow: hidden;
        font-weight: 300;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* Number of lines to show */
        text-overflow: ellipsis;
        white-space: wrap;
      }
      h4 {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
      }
      button {
        display: flex;
        border-style: solid;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
        padding: 4px 12px;
        cursor: pointer;
        width: 100%;
        flex-shrink: 0;
        white-space: break-spaces;
        color: rgb(30, 29, 29);
        font-weight: 300;
        font-size: 14px;
        background: rgb(201, 203, 212);
        border-width: 0px;
        border-color: rgba(63, 56, 103, 0);
        border-radius: 10px;
        height: 30px;
        margin-top: 7px;
        margin-bottom: 0;
      }
    }
  }
}
.item {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: waves 1s linear infinite;
  .item-dot-container {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}

@keyframes waves {
  0% {
    box-shadow: 0 0 0 0 white;
  }
  50% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }
}

.video-container {
  width: 58vw;
}
.video-add-conatiner-main {
  display: flex;
  gap: 10px;
}
.cart-btn-continer {
  background: black;
  color: white;
  overflow-wrap: wrap;
  &:hover {
    background-color: white;
  }
}
.item-highlight,
.item-highlight-left {
  position: relative;
  // width: 8px;
  // height: 8px;
  // border-radius: 50%;
  // background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 2px;
  //   border-radius: 5px;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   /* Calculate the midpoint between .item-highlight and .item-highlight-container */
  //   top: 100%;
  //   left: 49%;
  //   height: 97px;
  //   transform: translate(32px, -88px) rotate(43deg);
  // }
  .item-highlight-container {
    min-width: max-content;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 8px 12px;
    font-size: 13px;
    border-radius: 10px;
    position: absolute;
    top: -21px;
    left: -15px;
  }
}
.item-highlight-left {
  .item-highlight-container {
    // top: -100px;
    // left: -130px;
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 2px;
  //   border-radius: 5px;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   /* Calculate the midpoint between .item-highlight and .item-highlight-container */
  //   top: 100%;
  //   left: 49%;
  //   height: 93px;
  //   transform: translate(-31px, -87.5px) rotate(-39deg);
  // }
}
.item-main {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Add_tooltip_btn {
  &:hover {
    .content-add-icon {
      &::before {
        content: "To insert an item, begin by selecting the desired time on the video. Next, click here to indicate your selection. Finally, choose the coordinates where you'd like to insert the item within the video frame.";
        position: absolute;
        color: white;
        background-color: black;
        right: 43px;
        top: 0;
        min-width: 100px;
        font-size: 10px;
        font-weight: 700;
        padding: 5px;
        border-radius: 10px;
        outline: 1px solid white;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 49%;
      left: 100%;
      margin-left: -43px;
      border-width: 5.4px;
      border-style: solid;
      border-color: transparent transparent transparent white;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-left: -44.2px;
      border-width: 5px;
      border-style: solid;
      width: 5px;
      background: transparent transparent transparent white;
      border-color: transparent transparent transparent black;
    }
  }
}

.Total-shop-collection {
  color: white;
  background: black;
  padding: 8px 16px;
  font-size: 10px;
  white-space: no-wrap;
  border-radius: 8px;
  position: absolute;
  min-width: max-content;
  transition: all 0.1s ease-in-out 0s;
  cursor: pointer;
  animation: 2s cubic-bezier(0, 0.53, 0.58, 1) 0s infinite normal none running
    collection;
}
@keyframes collection {
  0% {
    box-shadow: rgba(0, 0, 0, 0.7) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.3) 0px 0px 2px 2px;
  }
  70% {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 15px,
      rgba(0, 0, 0, 0.3) 0px 0px 2px 2px;
  }
  100% {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.3) 0px 0px 2px 2px;
  }
}
.total_collection_wrap {
  width: 495px;
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 15px;
  transition: "transform 0.3s ease";
  // margin: 0 10px;
}
.total-collection-outer-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.total-collection-wrap-main {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 67%;
  justify-content: center;
  align-items: end;
}
.total-collection-inner-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.total-collection-container {
  display: block;
  // width: 104px;
  max-height: 300px;
  color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  background-color: rgba(37, 34, 34, 0.83);
  // white-space: pre-wrap;
  // font-weight: bold;
  // font-size: 20px;
  // font-family: Helvetica, Arial, sans-serif;
  // color: rgb(255, 254, 254);
  width: 150px;
  min-width: 150px;
  padding: 10px;
  img {
    width: 100%;
    height: auto;
    max-height: 120px;
    border-radius: 0;
    padding: 5px;
  }
  .image-container-wrap-total {
    width: 96%;
    height: 80px;
    border-radius: 0;
    border: 1px solid white;
    margin: 0 3%;
    margin-top: 5px;
  }
  h3 {
    font-size: 14px;
    margin-bottom: 3px;
    width: 100%;
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0;
  }
  h4 {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
  }
  p {
    font-size: 10px;
    margin-bottom: 3px;
    font-weight: 300;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Number of lines to show */
    text-overflow: ellipsis;
    white-space: wrap;
    margin-top: 0;
  }
  button {
    display: flex;
    border-style: solid;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    padding: 4px 12px;
    cursor: pointer;
    flex-shrink: 0;
    white-space: break-spaces;
    color: rgb(30, 29, 29);
    font-weight: 300;
    font-size: 15px;
    background: rgb(201, 203, 212);
    border-width: 0px;
    border-color: rgba(63, 56, 103, 0);
    border-radius: 10px;
    margin: 0;
    margin-top: 7px;
    height: 30px;
    width: 100%;
  }
}
.total-cross-btn-collection {
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: rgba(37, 34, 34, 0.83);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  svg {
    path {
      color: white;
      fill: white;
    }
  }
}

.right-collection-btn {
  height: 20px;
  path {
    // fill: white;
    stroke: white;
    color: white;
  }
}
@media (min-width: 200px) and (max-width: 900px) {
  .video-container {
    width: 100vw;
  }
}

#container {
  position: relative;
  width: 640px;
  height: 360px;
}
video#v {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
#container.fullscreen video#v {
  width: 100vw;
  height: 100vh;
}
#container {
  #overlay {
    display: block;
    position: absolute;
    top: 10px;
    left: 5%; /* Adjust as needed */
    color: pink;
    font-size: 5vw; /* Adjust font size for responsiveness */
    min-width: max-content;
  }
}
// #container .fullscreen {
//   #go_fullscreen {
//     position: absolute;
//     z-index: 1;
//     right: 5.8%;
//     bottom: 5.1%;
//     min-width: max-content;
//     cursor: pointer;
//     path {
//       fill: white;
//     }
//   }
// }
// #go_fullscreen {
//   position: absolute;
//   z-index: 1;
//   right: 7%;
//   bottom: 10%;
//   min-width: max-content;
//   cursor: pointer;
//   path {
//     fill: white;
//   }
// }
.half_screen_css {
  position: absolute;
  z-index: 1;
  right: 45px;
  bottom: 36px;
  min-width: max-content;
  cursor: pointer;
  path {
    fill: white;
  }
}

.full_screen_css {
  position: absolute;
  z-index: 1;
  right: 47px;
  bottom: 36px;
  min-width: max-content;
  cursor: pointer;
  path {
    fill: white;
  }
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
