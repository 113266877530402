.searchAddWrap {
  display: flex;
  justify-content: space-between;
}
.editIcon {
  padding-right: 10px;
}
.video_footer_container {
  width: 100% !important;
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.video_fie_edit {
  svg {
    path {
      color: #6f767e;
      fill: #6f767e;
    }
  }
}
