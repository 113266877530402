body {
    font-family: open sans;
  }
  .container {
    position: relative;
    max-width: 97%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: hidden;
    .row{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    .first_child_row{
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #1a1d1f;
      word-wrap: break-word;
      width: 50%;
    }
    .second_child_row{
      font-size: 14px;
    line-height: 16px;
      font-weight: 500;
      word-wrap: break-word;
      color: #6f767e;
      width: 50%;
    }
  }

  .plyr__control--overlaid {
    background-color: rgba(0, 0, 0, 0.6);
  }


  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded="true"] {
    background: rgba(0, 0, 0, 0.6);
  }

  .plyr--full-ui input[type="range"] {
    color: rgba(0, 0, 0, 0.6);
  }
  .overlay-heading {
    font-size: 20px;
    text-align: center;
    height: 50px;
    margin-top: 20px;
    position: sticky;
  }

  #overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%; /* Set the width to occupy 40% of the player width */
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: none; /* Hide the overlay by default */
    color: white;
    overflow-y: scroll;
    z-index: 10;
  }

  #overlay.visible {
    display: block; /* Show the overlay when it has the "visible" class */
    width: 40%;
    /* height: 98%; */
    height: 29.5vw;
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }

  .plyr--fullscreen-enabled
    .plyr__controls
    .plyr__control[data-plyr="fullscreen"] {
    color: white; /* Change the color of the fullscreen button icon */
    font-size: 20px; /* Adjust the font size of the fullscreen button icon */
    background-color: transparent; /* Set the background color of the button to transparent */
    border: none; /* Remove any border from the button */
    cursor: pointer; /* Set cursor to pointer when hovering over the button */
    display: none;
  }
  .plyr__control[data-plyr="pip"] {
    display: none;
  }

  #toggle-overlay-btn img {
    width: 20px; /* Adjust the size of the icon */
    /* background-color: none; */
    stroke-width: 5px;
  }

  #toggle-overlay-btn {
    position: absolute;
    /* background-color: none; */
    top: 10px;
    right: 10px;
    z-index: 100; /* Ensure the button appears above the overlay */
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease; /* Add transition effect to transform property */
  }
  #toggle-overlay-btn:hover {
    transform: scale(1.1); /* Scale up the button slightly on hover */
  }

  #toggle-overlay-btn:active {
    transform: scale(0.9); /* Scale down the button slightly when clicked */
  }

  .overlay-item {
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .overlay-item img {
    height: 150px;
    width: 65%;
    margin: auto;
  }
  .overlay-item p {
    text-align: center;
    line-height: 0px;
  }
  .overlay-item a {
    text-align: center;
    color: black;
    padding: 5px;
    text-decoration: none;
    background: whitesmoke;
    width: fit-content;
    margin: auto;
    font-size: 15px;
  }

  #image-container {
    display: flex;
    flex-direction: column;
  }

  /* Styling the scrollbar */
  #overlay::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }

  #overlay::-webkit-scrollbar-track {
    background: #333; /* Track color */
  }

  #overlay::-webkit-scrollbar-thumb {
    background: #666; /* Thumb color */
    border-radius: 5px; /* Border radius of the thumb */
  }

  #overlay::-webkit-scrollbar-thumb:hover {
    background: #999; /* Thumb color on hover */
  }
  #video-player{
    position: relative;
  }
  .bounding-box {
    position: absolute;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    .bound-box-child-container{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: grey;
    }  
  }
  /* #varun{
    height: 1000px;
  } */
  .Plyr {
    width: 800px !important; /* Adjust the width as needed */
    height: 600px !important; /* Adjust the height as needed */
  }
  
  #varun{
    border-radius: 15px;
    overflow: hidden;
  }

@media (min-width:1290px) and (max-width:1460px){
  #overlay{
    height: 29.8vw !important;
  }
}

@media (min-width:1290px) and (max-width:1460px){
  #overlay{
    height: 29.8vw !important;
  }
}

@media (min-width:1470px) and (max-width:1620px){
  #overlay{
    height: 30.1vw !important;
  }
}

@media (min-width:1630px) and (max-width:1720px){
  #overlay{
    height: 30.25vw !important;
  }
}
@media (min-width:1730px) and (max-width:7000px){
  #overlay{
    height: 30.5vw !important;
  }
}