.addFeild {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #3d9be9;
  font-size: 14px;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 3px;

    path {
      fill: #3d9be9;
    }
  }
}
.addRemoveContainer {
  margin-top: 1.2rem;
  cursor: pointer;
  display: flex;
  justify-content: end;
}
