.View-video-container {
  display: flex;
  height: calc(100vh - 144px);

  .Video-player-left {
    width: 60%;
    display: flex;
    justify-content: center;
    background-color: #f0f0f0;
    min-width: 200px;
    max-width: 100%;
    min-height: 200px;
    max-height: 100%;
    .container {
      // scale: 0.4;
    }

    .video-player {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .listed-items-container {
    padding: 5px;
    height: 68vh !important;
  }
  .Video-player-right {
    width: 40%;
    display: flex;
    gap: 15px;

    .listed-items,
    .unlisted-items {
      width: 100%;
      padding: 15px;
      padding-right: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .item-heading {
      font-size: 16px;
      padding-left: 5px;
      margin-bottom: 5px;
      font-weight: 600;
      width: 100%;
      z-index: 1;
    }
  }
  .item {
    padding: 5px;
    cursor: pointer;
    white-space: wrap;
    color: #212529;
    font-weight: 500;
    gap: 5px;
    &:hover {
      .edit-video-item-container {
        margin: auto 0 auto auto;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.items-list-container {
  height: 56.7vh;
  overflow-y: scroll;
  padding-right: 0 !important;
}

.edit-video-item-container {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}
.edit-item-icon-container {
  width: 16px;
  height: 16px;
}

@media (min-width: 1450px) and (max-width: 7000px) {
  .View-video-container .listed-items-container {
    padding: 5px;
    height: 69vh !important;
  }
}
