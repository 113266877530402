.upload-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  label{
    margin-bottom: 0;
  }
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 20px;
  height: 170px;
  cursor: grab;
  align-items: center;
  justify-content: center;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a{
    cursor: pointer;
    font-size: 15px;
  }
  font-size: 15px;
}
.upload-container-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    
}
.video-upload-progress{
  padding: 10px;
  background: whitesmoke;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 2px;
  progress{
    width: 100%;
  }
}
.preview-or-progress {
  height: 170px;
  /* width: 50%; */
}
.progress {
  margin-top: 20px;
}

.progress-bar {
  background-color: #007bff;
  width: 100%;
}
.video-file-anchor{
  color: blue !important;
}
.video-icon-container{
  width: 30px;
  height: 31px;
}
.video-upload-progress-content{
  display: flex;
  justify-content: space-between;
}