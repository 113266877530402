.wrapper {
  width: 100%;
  height:80px;
  padding: 10px;
  padding-left: 0;
}

.custom-form {
  width: 100%;
  height: 100%;
  flex-direction: column;
  input{
    padding-top: 10px;
    min-height: 46px !important;
  }
  button{
    width: 30%;
    margin: 0 !important;
  }
}

.btn {
  margin: 10px 0;
}

.progress {
  margin-top: 20px;
}

.progress-bar {
  background-color: #007bff;
}
