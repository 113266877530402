.accordion .accordion-item {
  margin-bottom: 24px !important;
  border-radius: 16px !important;
  border: 1px solid #efefef !important;
}

.accordion .accordion-header,
.accordion-button {
  border-radius: 16px !important;
}

.accordion .accordion-button {
  height: 61px !important;
}

.accordion .accordion-button:not(.collapsed) {
  color: $dark !important;
  background-color: #ffff !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-body {
  border-top: 1px solid #efefef;
  padding-bottom: 0;
}
