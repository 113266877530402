.item {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: wave 1s linear infinite;
}

@keyframes wave {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }
    50% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
    }
}

.video-container{
    width: 58vw;
}
.short-video-container{
    width: 18vw !important;
}
.video-add-conatiner-main{
    display: flex;
    gap: 10px;
    justify-content: center;
}
.video-add-icon{
    color:white;
    fill: white;
    stroke: white;
}
.video-add-icon-wrap{
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    border: solid 1px white;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}
.video-player-wrap{
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
    background: white;
    border-radius: 10px;
    margin: 10px 0 10px 0;
    button{
        margin-bottom: 0;
    }
}
.embeded-wrap{
    cursor: pointer;
}
.embed-video-wrap{
    /* background: azure; */
    padding: 5px;
    border-radius: 8px;
}


.shop-now-container {
    z-index: 10;
}

.shop-now-button {
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
    border: 0;
}

.video-js {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
}

.video-js .vjs-tech {
    object-fit: contain;
    /* Preserves aspect ratio, but might keep black bars */
}


.plyr-container {
    aspect-ratio: 16 / 9;
    width: 58vw !important;
    height: auto;
    object-fit: contain;
}



.player-switch-buttons {
    display: flex;
    gap: 10px;
    margin-top: 25px;
}

.player-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    background-color: #ccc;
    /* Default gray background */
    color: white;
}

.player-button.active {
    background-color: #3D9BE9;
    /* Blue background for the active button */
    color: white;
}

.player-button:disabled {
    background-color: #ccc;
    /* Gray background for the disabled button */
    color: white;
    cursor: not-allowed;
}

.player-button:not(.active):not(:disabled) {
    background-color: #3D9BE9;
    /* Blue background for the clickable button */
    color: white;
}

/* .shop-now-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
} */