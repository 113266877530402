.image-selector {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  margin-bottom: 16px;
  button {
    scale: 0.8;
    margin-bottom: 0;
  }
}
.delete-video-container {
  svg,
  path {
    fill: #be0f00;
  }
  &:hover {
    svg,
    path {
      fill: white;
    }
  }
}
.edit-video-container {
  svg,
  path {
    fill: #3d9be9;
  }
  &:hover {
    svg,
    path {
      fill: white;
    }
  }
}
.image-preview {
  width: 60px;
  height: 60px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  background-color: white;
}

.image-view-container {
  width: 120px;
  max-height: auto;
  float: left;
  margin: 3px;
  padding: 3px;
  img {
    max-width: 100%;
    height: auto;
  }
}
.image-selector {
}

.Month-year-selector {
  input {
    font-weight: 500 !important;
    color: #1a1d1f !important;
  }
  button {
    color: black;
    width: 45px !important;
  }
  :first-child {
    width: 100%;
  }
}
