.dashboard {
  background-color: #f4f4f4;
  min-height: 100vh;
  width: 100%;
}

.dashboard-body {
  padding: 32px 60px 0;
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;

  .footer {
    width: calc(
      100% + 120px
    ); /* 100% + left and right padding of .dashboard-body */
    margin-left: -60px;
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  .dashboard-body {
    padding: 0;
  }
}
