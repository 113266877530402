$white: #ffffff;
$light: #efefef;
$grey: #6f767e;
$lightGrey: #ced5de;
$dark: #1a1d1f;
$green: #83bf6e;
$danger: #be0f00;
$yellow: #ffa700;
$primary: #3d9be9;

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.view-muted,
.view-muted-16 {
  color: var(--80, #6f767e);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
}
