.add_preview_container {
  height: 74.7vh;
  overflow: scroll;
  padding: 1rem;
  padding-bottom: 0;
  &::-webkit-scrollbar {
    width: 2px;
  }
}
.video_main_preview_container {
  border-radius: 15px;
  overflow: hidden;
}
.items_type_add_container {
  border-radius: 15px;
  border: 2px solid black;
  width: max-content;
  overflow: hidden;
  cursor: pointer;
  .hover_item_btn {
    padding: 10px;
  }
  .cart_item_btn {
    padding: 10px;
  }
  .active_item_btn {
    padding: 10px;
    background-color: black;
    color: white;
  }
}
.coordinates_pick_link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}


.loadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loadingMessage {
  font-size: 16px;
  color: #333;
  text-align: center;
  margin-top: 10px;
}