.form-group {
  display: flex;
  flex-direction: column;
}

input,
textarea {
  border-radius: 10px;
  background: var(--10, #f4f4f4);
  border: none;
  flex: 1;
  font-size: 14px;
  padding: 16px 20px;
  transition: box-shadow 0.2s ease; /* Add transition for box-shadow */
  font-weight: 500 !important;
  color: #333 !important;
  outline: none;
}

label {
  color: #333;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 16px; /* 114.286% */
  margin-bottom: 12px;
}
.input-group {
  width: 100%;
  border-radius: 10px;
}
.input-group-append {
  height: 48px;
  flex-shrink: 0;
  padding: 12px 20px;
  background: var(--10, #f4f4f4);
  border-radius: 0px 10px 10px 0px;
  align-items: center;
  justify-content: center;
}

.input-group input {
  border-right: 0 !important;
}

.input-group .input-group-append {
  border-left: 0 !important ;
}

input {
  height: 48px;
}

textarea {
  flex: none;
  min-height: 80px;
  max-height: 400px;
}

.info {
  color: #3d9be9;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.14px;
  cursor: pointer;
}

// footer
.form-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  border-radius: 3px; /* Border radius of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar */
  border-radius: 3px; /* Border radius of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar on hover */
}

.error-input {
  border: 1px solid #be0f00;
}

// time picker
input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none !important;
}
