@import "../../../assets/globalStyle/variables.scss";

.navbar-container {
  position: sticky;
  z-index: 1000;
  top: 0;
}

.navbar ul,
.switching-header ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

a {
  text-decoration: none !important;
  color: black;
  font-size: 18px;
}

/* navbar */
.navbar {
  background-color: $dark;
  padding: 10px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  height: 72px;
  z-index: 0;
}

.navbar svg {
  height: 20px;
  width: 20px;
  font-size: 20px;
  color: $dark;
  margin-right: 10px;
}

.navLink {
  display: flex;
  align-items: center;
  color: #9a9fa5;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 20px;
  transition: color 0.3s;

  svg {
    display: none;
  }
}

.navLink:hover {
  color: #efefef !important;
  cursor: pointer;
}

.active-nav {
  color: #efefef !important;
  border-radius: 60px;
  background: #353b3f;
  transition: background 0.3s, color 0.3s;
  svg {
    display: block;
  }
  path {
    fill: #efefef;
    transition: 0.3s ease;
  }
}

/* ul  */
.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100000;
}

.navbar .left-side {
  display: flex;
  align-items: center;
  // flex: 1;
  gap: 10px;
  justify-content: flex-end;
  i {
    color: #9a9fa5;
    font-size: 20px;
    line-height: normal;
  }
}

@media screen and (max-width: 1375px) {
  .navbar {
    gap: 10px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 1233px) {
  /* hamburger */
  .hamburger {
    display: block;
    cursor: pointer;
  }
  .bar {
    background-color: #9a9fa5;
    width: 20px;
    height: 2px;
    display: block;
    margin: 4px;
    -webkit-transition: 0.3s;
    transition: 0.3s ease-in-out;
  }
  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }
  .navMenu {
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: #333;
    padding: 10px;
  }
  .navMenu.active {
    left: 0;
    // z-index: 100000;
  }
  .navMenu li {
    margin: 16px 0;
  }
}

.navbar img {
  height: 45px !important;
}
