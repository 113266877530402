// Date Picker
.MuiInputBase-root,
.MuiOutlinedInput-root {
  background-color: #f4f4f4 !important;
  height: 48px;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  color: #1a1d1f;
}
